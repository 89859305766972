import React from "react";
import clsx from "clsx";

import { ButtonPageLinkProps } from "./types";
import { Link } from "gatsby";
import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import SliceSVG from "modules/theme/ims/button-slice.svg";

const PageLink: React.FunctionComponent<ButtonPageLinkProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  label,
  itemProp,
  onClick,
  roundedCorner,
  slug,
  state,
  style,
  target,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  const buttonClassName = clsx(
    style && "btn",
    style && `${style?.replace(/\s/g, "")}`,
    disabled && "disabled",
    className,
    style && roundedCorner && "roundedCorner"
  );

  return (
    <Link
      aria-label={ariaLabel ? ariaLabel : label}
      className={buttonClassName === "" ? null : buttonClassName}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      itemProp={itemProp}
      state={state}
      tabIndex={disabled ? -1 : 0}
      target={target}
      to={slug ? slug : "/"}
    >
      {label && <span>{label}</span>}
      {style && !roundedCorner && (
        <>
          <ChevronSVG className="buttonChevron" />
          <SliceSVG className="buttonSlice" />
        </>
      )}
      {children}
    </Link>
  );
};

export default React.memo(PageLink);
