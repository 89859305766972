import { useCallback, useEffect, useRef } from "react";

type UseHandleClickProps = (props: {
  disabled?: boolean;
  readOnly?: boolean;
  onClick: () => void;
}) => (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => void;

const useHandleClick: UseHandleClickProps = ({ disabled, readOnly, onClick }) => {
  const handleClickRef = useRef(
    (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => {}
  );
  const handleClick = useCallback((event) => handleClickRef.current(event), []);

  useEffect(() => {
    handleClickRef.current = (event) => {
      event.preventDefault();
      if (!disabled && !readOnly) {
        onClick && onClick();
      }
    };
  }, [disabled, readOnly, onClick]);

  return handleClick;
};

export default useHandleClick;
