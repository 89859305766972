import React from "react";
import clsx from "clsx";
import { setAriaLabel, setTabIndex } from "./helpers";

import { ButtonSubmitProps } from "./types";
import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import SliceSVG from "modules/theme/ims/button-slice.svg";
import useHandleMouseDown from "./hooks/handleMouseDown";

const Submit: React.FunctionComponent<ButtonSubmitProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  id,
  isSubmitting,
  label,
  onClick,
  readOnly,
  roundedCorner,
  style,
  tabIndex,
}) => {
  const buttonClassName = clsx(
    style && "btn",
    style && `${style?.replace(/\s/g, "")}`,
    disabled && "disabled",
    readOnly && "readOnly",
    className,
    style && roundedCorner && "roundedCorner"
  );

  const handleMouseDown = useHandleMouseDown();

  return (
    <>
      {isSubmitting && <span className="spinner"></span>}
      <button
        aria-label={setAriaLabel(ariaLabel, label)}
        className={buttonClassName === "" ? null : buttonClassName}
        disabled={disabled}
        id={id}
        onClick={onClick}
        onMouseDown={handleMouseDown}
        tabIndex={setTabIndex(disabled, tabIndex)}
        type="submit"
      >
        {label && <span>{label}</span>}
        {style && !roundedCorner && (
          <>
            <ChevronSVG className="buttonChevron" />
            <SliceSVG className="buttonSlice" />
          </>
        )}
        {children}
      </button>
    </>
  );
};

export default React.memo(Submit);
