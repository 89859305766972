export const setTabIndex = (disabled?: boolean, tabIndex?: number) => {
  if (disabled) {
    return -1;
  }
  return tabIndex ? tabIndex : 0;
};

export const setAriaLabel = (ariaLabel?: string, label?: string) => {
  if (ariaLabel) {
    return ariaLabel;
  }
  return label ? label : undefined;
};
