import React from "react";
import clsx from "clsx";

import { addProtocol } from "modules/utils";
import { ButtonCustomLinkProps } from "./types";
import { ButtonTarget } from "./constants";
import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import SliceSVG from "modules/theme/ims/button-slice.svg";

const CustomLink: React.FunctionComponent<ButtonCustomLinkProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  href,
  label,
  onClick,
  itemProp,
  roundedCorner,
  style,
  target = ButtonTarget.BLANK,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };
  const buttonClassName = clsx(
    style && "btn",
    style && `${style?.replace(/\s/g, "")}`,
    disabled && "disabled",
    className,
    style && roundedCorner && "roundedCorner"
  );

  return (
    <a
      aria-label={ariaLabel ? ariaLabel : label}
      className={buttonClassName === "" ? null : buttonClassName}
      href={href != null ? addProtocol(href) : undefined}
      itemProp={itemProp}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      tabIndex={disabled ? -1 : 0}
      target={target}
    >
      {label && <span>{label}</span>}
      {style && !roundedCorner && (
        <>
          <ChevronSVG className="buttonChevron" />
          <SliceSVG className="buttonSlice" />
        </>
      )}
      {children}
    </a>
  );
};

export default React.memo(CustomLink);
