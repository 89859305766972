import React from "react";
import clsx from "clsx";
import { setAriaLabel, setTabIndex } from "./helpers";
import useHandleClick from "./hooks/handleClick";
import useHandleMouseDown from "./hooks/handleMouseDown";

import { ButtonGenericProps } from "./types";
import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import SliceSVG from "modules/theme/ims/button-slice.svg";

const Generic: React.FunctionComponent<ButtonGenericProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  forwardedRef,
  id,
  label,
  onClick,
  readOnly,
  roundedCorner,
  style,
  tabIndex,
}) => {
  const buttonClassName = clsx(
    style && "btn",
    style && `${style?.replace(/\s/g, "")}`,
    disabled && "disabled",
    readOnly && "readOnly",
    className,
    style && roundedCorner && "roundedCorner"
  );

  const handleClick = useHandleClick({ disabled, readOnly, onClick });
  const handleMouseDown = useHandleMouseDown();

  return (
    <button
      aria-label={setAriaLabel(ariaLabel, label)}
      className={buttonClassName === "" ? null : buttonClassName}
      disabled={disabled}
      id={id}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={forwardedRef}
      tabIndex={setTabIndex(disabled, tabIndex)}
      type="button"
    >
      {label && <span>{label}</span>}
      {style && !roundedCorner && (
        <>
          <ChevronSVG className="buttonChevron" />
          <SliceSVG className="buttonSlice" />
        </>
      )}
      {children}
    </button>
  );
};

export default React.memo(Generic);
