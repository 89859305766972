import DOMPurify from "isomorphic-dompurify";
import parse from "html-react-parser";

/* -- Rich Text Functions -- */
/* ---------------------------------------------------------------------- */
// Convert Sting To HTML
export const htmlSafe = (string: string) => {
  const clean = DOMPurify.sanitize(string);
  return typeof string === "string" ? parse(clean) : string;
};

// Truncate Words
export const truncate = (string: string, maxLen: number, separator = " ") => {
  if (!string || string.length <= maxLen) return string;
  return `${string.substr(0, string.lastIndexOf(separator, maxLen))}...`;
};

// Remove HTML Tags
export const stripTags = (string: string) => {
  return string?.replace(/<[^>]+>/g, "");
};

// Remove <p> Tags
export const stripParagraphTags = (string: string) => {
  return string?.replace(/<\/?p[^>]*>/g, "");
};

// Wrap <table> Tags With Table Wrapper
export const wrapTableTags = (string: string) => {
  return string?.replace(/<table>/g, "<div class='table'><table>").replace(/<\/(table)+>?/g, "</table></div>");
};

// Check If String Is Missing Start And Closing Tags
export const hasStartAndEndTag = (string: string) => {
  return /^\<|>$/.test(string);
};

// Wrap Registered Symbol With Sup Tags
export const wrapRegisteredSymbol = (string: string) => {
  return string?.replace(/®/g, "<sup>®</sup>");
};

/* -- Window Validation -- */
/* ---------------------------------------------------------------------- */
export const useWindow = () => typeof window !== "undefined" && window;

/* -- Add Protocol -- */
/* ---------------------------------------------------------------------- */
export const addProtocol = (url: string) => {
  return `${url.substr(0, 4) !== "http" && url.substr(0, 2) !== "//" ? "//" : ""}${url}`;
};

/* -- Convert Object To Query String -- */
/* ---------------------------------------------------------------------- */
export const objectToQueryString = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && typeof obj[p] !== undefined && obj[p] !== null) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

/* -- Calculate Device DPI -- */
/* ---------------------------------------------------------------------- */
export const useDpr = () => (useWindow() ? window.devicePixelRatio : 1);

/* -- Merge Multiple Refs -- */
/* ---------------------------------------------------------------------- */
export const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

/* -- Flatten Object And Return Array -- */
/* ---------------------------------------------------------------------- */
export const flattenObject = (ob: any) => {
  let toReturn: any = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
