export enum ButtonType {
  BUTTON = "button",
  CUSTOM_LINK = "custom-link",
  PAGE_LINK = "page-link",
  SUBMIT = "submit",
}

export enum ButtonStyle {
  BRAND_AQUA = "Aqua",
  BRAND_BLACK_GRAPE = "Black Grape",
  BRAND_BERRY = "Berry",
  WHITE = "White",
  PRODUCT_PROPATH = "Product ProPath",
  PRODUCT_ITL = "Product Itil",
  PRODUCT_TRANSFORMATION = "Product Transformation",
  PRODUCT_MATURITY = "Product Maturity",
  PRODUCT_RESILIA = "Product Resilia",
}

export enum ButtonTarget {
  BLANK = "_blank",
  SELF = "_self",
}
